<template>
  <div id="lineoptions">
    <el-form-item label="Line Style">
      <el-radio-group @change="updateChart" v-model="stroke.curve" size="small">
        <el-radio-button label="smooth"></el-radio-button>
        <el-radio-button label="straight"></el-radio-button>
        <el-radio-button label="stepline"></el-radio-button>
      </el-radio-group>
    </el-form-item>

    <h4>Line Width</h4>
    <div class="block">
      <el-slider
        @change="updateChart"
        v-model="stroke.width"
        :max="30"
        :min="0"
        show-input
      ></el-slider>
    </div>

    <h4>Line Dash Array</h4>
    <div class="block">
      <el-slider
        @change="updateChart"
        v-model="stroke.dashArray"
        :max="30"
        :min="0"
        show-input
      ></el-slider>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      stroke: {
        curve: "smooth", // "smooth" / "straight" / "stepline"
        width: 2,
        dashArray: 0, // single value or array of values
      },
    };
  },
  watch: {
    chartConfig: function(a) {
      this.setNewOptions(a);
    },
  },
  mounted: function() {
    const a = this.getChartConfig().w.config;
    this.setNewOptions(a);
  },
  methods: {
    setNewOptions(a) {
      this.stroke = {
        curve: a.stroke.curve, // "smooth" / "straight" / "stepline"
        width: a.stroke.width,
        dashArray: a.stroke.dashArray,
      };
    },
    updateChart: function() {
      this.updateChartOptions({
        stroke: {
          curve: this.stroke.curve,
          dashArray: this.stroke.dashArray,
          width: this.stroke.width,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
